import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Typed from 'typed.js';
import './About.css';
import './Utils.css';
import AboutImage from '../assets/about/5-main.svg';
import EmailImage from '../assets/about/2-email.svg';
import GitHubImage from '../assets/about/3-github.svg';
import LinkedInImage from '../assets/about/4-linkedin.svg';
import TelegramImage from '../assets/about/6-telegram.svg';
import InstagramImage from '../assets/about/7-instagram.svg';
import MRKLogo from '../assets/about/mrk.png';

const AboutComponent = ({ backgroundColor }) => {
  const el = useRef(null);
  const typed = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        'Front-end developer',
        'Backend developer',
        'Wordpress developer'
      ],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <div style={{ backgroundColor }} className="d-flex justify-content-around flex-wrap">
      <div style={{ marginLeft: 20, marginTop: 40, display: 'inline-flex' }} className="flex-column">
        <img src={MRKLogo} alt="Mohammad reza kohan" width="150" />
        <div className="animated-underline mt-3">
          <h1>Hi, I'm Mohammadreza Kohan</h1>
          <h2 style={{ display: 'inline' }}>
            <span style={{ whiteSpace: 'pre', color: '#0f52ba' }} ref={el} />
          </h2>
        </div>

        <p className="content">
          I'm a passionate developer with a keen interest in web platforms. My enthusiasm lies in harnessing web technologies to craft innovative solutions for real-world challenges. To achieve this, I constantly strive to expand my knowledge in both frontend and backend development simultaneously.
        </p>

        <div>
          {[
            { href: 'https://www.linkedin.com/in/mrkohan/', src: LinkedInImage, alt: 'LinkedIn icon' },
            { href: 'https://github.com/mrkohan', src: GitHubImage, alt: 'GitHub icon' },
            { href: 'mailto:mrkohan@live.com', src: EmailImage, alt: 'Email icon' },
            { href: 'https://t.me/mrkohan', src: TelegramImage, alt: 'Telegram icon' },
            { href: 'https://instagram.com/mrezakohan', src: InstagramImage, alt: 'Instagram icon' }
          ].map(({ href, src, alt }) => (
            <a key={href} href={href} target="_blank" rel="noopener noreferrer">
              <img src={src} className="icons" alt={alt} />
            </a>
          ))}
        </div>

        <Container style={{ marginLeft: -15, paddingBottom: 120, marginTop: 60 }}>
          <Row>
            <Col>
              <em>
                <img
                  align="center"
                  alt="github status"
                  src="https://github-readme-stats.vercel.app/api/top-langs/?username=mrkohan&layout=compact"
                />
              </em>
            </Col>
          </Row>
        </Container>
      </div>
      <img
        src={AboutImage}
        className="illustration"
        alt="Illustration of devices on different platforms."
      />
    </div>
  );
};

export default AboutComponent;
import React from 'react';
import EmailImage from '../assets/about/2-email.svg';
import GitHubImage from '../assets/about/3-github.svg';
import LinkedInImage from '../assets/about/4-linkedin.svg';
import TelegramImage from '../assets/about/6-telegram.svg';
import InstagramImage from '../assets/about/7-instagram.svg';
import MRKLogo from '../assets/about/mrk.png';
import './footer.css';
const FooterComponent = () => {
  return (

    <div className="d-flex flex-column">
      <img className="logo-footer" src={MRKLogo} alt="Mohammad reza kohan" width="150" />
      <div className="d-flex justify-content-center" style={{ paddingBottom: 20 }}>
        
        <a href="https://www.linkedin.com/in/mrkohan/">
          <img src={LinkedInImage} className="icons" alt="LinkedIn icon" />
        </a>
        <a href="https://github.com/mrkohan">
          <img src={GitHubImage} className="icons" alt="GitHub icon" />
        </a>
        <a href="mailto:mrkohan@live.com">
          <img src={EmailImage} className="icons" alt="Gmail icon" />
        </a>
        <a href="https://t.me/mrkohan">
          <img src={TelegramImage} className="icons" alt="Gmail icon" />
        </a>
        <a href="https://instagram.com/mrezakohan">
          <img src={InstagramImage} className="icons" alt="Gmail icon" />
        </a>
      </div>
      <p className="d-flex justify-content-center copyright" style={{ fontSize: 12 }}>The icons used in this page belong to their respective owners and/or organizations.</p>
      <p className="d-flex justify-content-center" style={{ fontSize: 24 }}>&#169; 2024 Mohammad reza Kohan.</p>
    </div >


  )
}

export default FooterComponent;
import React from 'react';
import {
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  CardSubtitle
} from 'reactstrap';

/**
 * CardComponent - A reusable card component for displaying project details
 *
 * @param {string} image - URL of the card image.
 * @param {string} alt - Alt text for the image.
 * @param {string} title - Title of the card.
 * @param {string} subtitle - Subtitle of the card.
 * @param {string} text - Text description in the card.
 * @param {string} livesite - URL of the live site to link to.
 * @returns {JSX.Element} A card UI component.
 */
const CardComponent = ({ image, alt, title, subtitle, text, livesite }) => {
  return (
    <Card style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
      <CardImg bottom height="300" src={image} alt={alt} />
      <CardBody>
        <CardTitle tag="h5" style={{ color: 'black' }}>
          {title}
        </CardTitle>
        {/* Use pre-wrap to process \n char */}
        <CardSubtitle tag="em" style={{ whiteSpace: 'pre-wrap' }}>
          {subtitle}
        </CardSubtitle>
        <CardText
          style={{
            color: 'black',
            marginTop: 20,
            whiteSpace: 'pre-wrap',
            marginBottom: 50
          }}
        >
          {text}
        </CardText>
        {livesite && (
          <Button
            href={livesite}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: 15 }}
          >
            Live site
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default CardComponent;

import React, { useState, useEffect } from 'react';
import {
  TabContent, TabPane, CardDeck 
} from 'reactstrap';
import FooterComponent from './Footer';
import './Projects.css'
import CardComponent from './Card';


const ProjectsComponent = ({ backgroundColor }) => {
  const [activeTab] = useState('1');
  const [projects, setProjects] = useState([]);
  const [expandedProjectIds, setExpandedProjectIds] = useState([]);


  useEffect(() => {
    // Fetch projects from the API
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://back.kohan.com.tr/wp-json/wp/v2/projects?_embed&per_page=40');
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  // To collapse nav
  const [setIsOpen] = useState(false);
  let windowWidth;
  const resetOpen = () => {
    windowWidth = window.innerWidth;
    if (windowWidth >= 768) setIsOpen(false);
  }
  window.addEventListener('resize', resetOpen);
  const handleToggleExpand = (projectId) => {
    setExpandedProjectIds((prevIds) =>
      prevIds.includes(projectId)
        ? prevIds.filter((id) => id !== projectId)
        : [...prevIds, projectId]
    );
  };


  return (
    <div style={{ backgroundColor }} className="container-projects">
      <div className="d-flex justify-content-center" style={{ paddingBottom: 20 }}>
        <div className="animated-underline">
          <h2>Projects</h2>
        </div>
      </div>

      <div style={{ maxWidth: '65vmax', margin: 'auto', backgroundColor: 'white', borderRadius: 25, marginBottom: 200, padding: 20 }}>
        <TabContent activeTab={activeTab} className="d-flex justify-content-center ">
          <TabPane tabId="1">
             <CardDeck>
              {projects.filter(project => project.acf?.is_active).map((project) => {
                  const fullText = project.content.rendered.replace(/<[^>]*>?/gm, '');
                  const shortText = fullText.length > 100 ? `${fullText.substring(0, 100)}...` : fullText;
                  const isExpanded = expandedProjectIds.includes(project.id);

                return (
                  <CardComponent
                    key={project.id}
                    image={project._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.full?.source_url || ''}
                    alt={project.title.rendered}
                    title={project.title.rendered}
                    subtitle={project.acf?.subtitle || 'WordPress | Custom Development'}
                    text={
                      isExpanded ? fullText : (
                        <>
                          {shortText}
                          {fullText.length > 100 && (
                            <button onClick={() => handleToggleExpand(project.id)} className="load-more-btn">Load more</button>
                          )}
                        </>
                      )
                    }
                    livesite={project.acf?.livesite || null}
                    disableButton={!project.acf?.livesite}
                  />
                );
              })}
            </CardDeck>
          </TabPane>
        </TabContent>
      </div>

      <FooterComponent />
    </div>
  );
};

export default ProjectsComponent;
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Utils.css';
import './Skills.css';

import JavaScriptLogo from '../assets/skills/languages/1-js-logo.svg';
import TsLogo from '../assets/skills/languages/4-ts-logo.svg';
import ScssLogo from '../assets/skills/languages/9-sass-logo.svg';
import PhpLogo from '../assets/skills/languages/8-php-logo.svg';

import ReactLogo from '../assets/skills/frameworks/1-react-logo.svg';
import DockerLogo from '../assets/skills/other/5-docker-logo.svg';
import JqueryLogo from '../assets/skills/frameworks/4-jquery-logo.svg';
import BootstrapLogo from '../assets/skills/frameworks/5-bootstrap-logo.svg';
import VueLogo from '../assets/skills/frameworks/6-vue-logo.svg';
import LaravelLogo from '../assets/skills/languages/8-laravel-logo.svg';

import WindowsLogo from '../assets/skills/os/1-windows-logo.svg';
import LinuxLogo from '../assets/skills/os/2-linux-logo.svg';
import MacosLogo from '../assets/skills/os/4-macos-logo.svg';
import AndroidLogo from '../assets/skills/os/3-android-logo.svg';

import GitLogo from '../assets/skills/other/1-git-logo.svg';
import PostmanLogo from '../assets/skills/other/2-postman-logo.svg';
import WpLogo from '../assets/skills/other/3-wordpress-logo.svg';
import ElementorLogo from '../assets/skills/other/4-Elementor-logo.svg';


/* Receive props: backgroundColor1, backgroundColor2 */
const SkillsComponent = (props) => {

  const styles = {
    backgroundImage: `linear-gradient(to bottom, ${props.backgroundColor1} , ${props.backgroundColor2})`
  }

  return (
    <div style={styles}>
      <div className="container-skills">
        <div className="d-flex justify-content-center" style={{ paddingBottom: 20 }}>
          <div className="animated-underline" style={{paddingTop: 30}}>
            <h2>Skills</h2>
          </div>
        </div>

        <Container>
          <Row>
            <Col xl="3" md="6" xs="12">
              <Row className="mb-3"><img src={PhpLogo} alt="PHP Logo" className="icons" style={{ height: 50 }}/></Row>
              <Row className="mb-3"><img src={LaravelLogo} alt="Laravel Logo" className="icons" /></Row>
              <Row className="mb-3"><img src={TsLogo} alt="TS Logo" className="icons" /></Row>
              <Row className="mb-3"><img src={JavaScriptLogo} alt="JavaScript Logo" className="icons" /></Row>
              
            </Col>

            <Col xl="3" md="6" xs="12">
              <Row className="mb-3"><img src={ReactLogo} alt="React and React Native Logo" className="icons" /></Row>
              <Row className="mb-3"><img src={VueLogo} alt="Vue Logo" className="icons" style={{ width: 80 , height: 50 }}  /></Row>
              <Row className="mb-3"><img src={ScssLogo} alt="SCSS Logo" className="icons" style={{ height: 50 }} /></Row>
              <Row className="mb-3"><img src={BootstrapLogo} alt="Bootstrap Logo" className="icons" /></Row>
              
            </Col>

            <Col xl="3" md="6" xs="12">
              <Row className="mb-3"><img src={MacosLogo} alt="IOS Logo" className="icons" /></Row>
              <Row className="mb-3"><img src={WindowsLogo} alt="WindowsLogo" className="icons" /></Row>
              <Row className="mb-3"><img src={AndroidLogo} alt="Android Logo" className="icons" /></Row>
              <Row className="mb-3"><img src={LinuxLogo} alt="Linux Logo" className="icons" /></Row>
            </Col>

            <Col xl="3" md="6" xs="12">
              <Row className="mb-3"><img src={WpLogo} alt="Wordpress Logo" className="icons"/></Row>
              <Row className="mb-3"><img src={ElementorLogo} alt="Elementor Logo" className="icons" style={{ width: 120, height: 50 }} /></Row>
              <Row className="mb-3"><img src={GitLogo} alt="Git version control system Logo" className="icons" style={{ width: 80 }} /></Row>
              <Row className="mb-3"><img src={PostmanLogo} alt="Postman Logo" className="icons" style={{ width: 96, height: 60  }} /></Row>
            </Col>
          </Row>



        </Container>

      </div>
    </div>
  );
}

export default SkillsComponent;

import React, { useEffect, useState } from 'react';
import { Chrono } from "react-chrono";
import './Experience.css';

const ExperienceComponent = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchExperience = async () => {
      try {
        const response = await fetch('https://back.kohan.com.tr/wp-json/wp/v2/experience?_embed&per_page=40');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const fetchedItems = data.map((item) => {
          const decodedTitle = new DOMParser().parseFromString(item?.title?.rendered, "text/html").body.textContent || "Untitled Experience";
          const featuredImage = item?._embedded?.['wp:featuredmedia']?.[0]?.source_url || "";
          return {
            title: `${item.acf?.from || ""} - ${item.acf?.to || ""}`,
            cardDetailedText: (
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {featuredImage && (
                  <img
                    src={featuredImage}
                    alt={decodedTitle || "Featured Image"}
                    className="experience-image"
                    style={{ width: 100, height: 100, marginRight: 25,marginTop:40, objectFit: 'contain', borderRadius: 8 }}
                  />
                )}
                  <div>
                    <h4>
                      <a href={item.acf?.companys_site || "#"} target="_blank" rel="noopener noreferrer">
                        {item.acf?.position || "Untitled Experience"}
                      </a>
                    </h4>
                    <p className="position">{decodedTitle || ""} | {item.acf?.country || ""}</p>
                    <div dangerouslySetInnerHTML={{ __html: item?.content?.rendered || "No description available" }} />

                  </div>
              </div>
            ),
          };
        });
        setItems(fetchedItems);
      } catch (error) {
        console.error('Error fetching experience data:', error);
      }
    };

    fetchExperience();
  }, []);

  return (
    <div style={{ backgroundColor: props.backgroundColor }} className="container-experience">
      <div className="d-flex justify-content-center">
        <div className="animated-underline">
          <h2> Experience</h2>
        </div>
      </div>
      <div style={{ marginLeft: "4.5vw", paddingBottom: 140, width: "90vw" }}>
        {items.length > 0 ? (
          <Chrono
            items={items}
            mode="VERTICAL"
            enableOutline
            slideShow
            slideItemDuration={4500}
            scrollable
            slideShowType="reveal"
          />
        ) : (
          <p>Loading experiences...</p>
        )}
      </div>
    </div>
  );
}

export default ExperienceComponent;